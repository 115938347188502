import React from "react";
import ReactDOMClient from "react-dom/client";
import { Frame } from "./screens/Frame";
import FrameNew from "./screens/Frame/frameNew";
import FrameNewV3 from "./screens/Frame/frameNewV3";
import FrameNewV4 from "./screens/Frame/frameNewV4";
import FrameNewV5 from "./screens/Frame/DeepGram";
import FrameNewV6 from "./screens/Frame/DeepGramV2";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import DashboardPage from "./screens/Frame/Dashboard";
const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
// root.render(<Frame />);
root.render(
    <Router>
      <Routes>
        <Route path="/" element={<FrameNewV4 />} />
        <Route path="/:version" element={<FrameNew />} />
        <Route path="/version3" element={<FrameNewV5 />} />
        <Route path="/version4" element={<FrameNewV6 />} />
        <Route path="/version5" element={<FrameNewV3 />} />
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    </Router>


);
