import React, { useState, useRef, useEffect } from 'react';
import './frameNew.css';
import axios from "axios";
import { Typography, Grid, Button } from '@mui/material';
import playbuttons3 from '../../../static/img/mic_ico.png'
import refresh from '../../../static/img/outputrefresh.gif'
import microphoneoff from '../../../static/img/icons8-microphone-off-64.png'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import GoogleTranslate from './GoogleTranslater';
import Webcam from "react-webcam";
var socket;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const MessageInputDeepGram2 = () => {
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  document.title = "Synergy Form Fillig App"
  const { version } = useParams()
  const [listofaudios, setlistofaudios] = useState([])
  const [isRecording, setIsRecording] = useState(false);
  const [successmsg, setsuccessmsg] = useState(false)
  const [userinput, setuserinput] = useState(false)
  const [session_id,  setsession_id] = useState(Math.floor(100000 + Math.random() * 900000))
  const [name, setName] = useState("")
  const [name2, setName2] = useState("")
  const [name3, setName3] = useState("")
  const [name4, setName4] = useState("")
  const [name5, setName5] = useState("")
  const [name6, setName6] = useState("")
  const [name7, setName7] = useState("")
  const [name8, setName8] = useState("")
  const [name9, setName9] = useState("")
  const [name10, setName10] = useState("")
  const [name11, setName11] = useState("USA")
  const [name12, setName12] = useState("")
  const [playvideoandaudio, setplayvideoandaudio] = useState(true)
  const videoRef = useRef(null);
  const mediaStreamRef = useRef();
  const audioContextRef = useRef([]);
  const analyserRef = useRef([]);
  const dataArrayRef = useRef([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setName("")
    setName2("")
    setName3("")
    setName4("")
    setName5("")
    setName6("")
    setName7("")
    setName8("")
    setName9("")
    setName10("")
    setName11("")
    setName12("USA")
    setsession_id(Math.floor(100000 + Math.random() * 900000))
    setOpen(false);
    setplayvideoandaudio(true)
    setsuccessmsg(false)
    sessionStorage.setItem("userinput", false)
    sessionStorage.removeItem('apiresponse')
    sessionStorage.removeItem("videonumber")
    sessionStorage.removeItem("recording")
  };
  const refreshfields = () => {
    setName("")
    setName2("")
    setName3("")
    setName4("")
    setName5("")
    setName6("")
    setName7("")
    setName8("")
    setName9("")
    setName10("")
    setName11("")
    setName12("USA")
    setOpen(false);
    setsession_id(Math.floor(100000 + Math.random() * 900000))
    setplayvideoandaudio(true)
    setsuccessmsg(false)
    setshowkeyboard(false)
    setdisablebutton(false)
    sessionStorage.removeItem("videonumber")
    sessionStorage.removeItem("recording")
    sessionStorage.removeItem('apiresponse')
    sessionStorage.setItem("userinput", false)
  }
  useEffect(() => {
    // getVideosfromUser();
    sessionStorage.removeItem("videonumber")
    sessionStorage.removeItem("recording")
    sessionStorage.setItem("userinput", false)
    sessionStorage.removeItem('apiresponse')
  }, [])
  function isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  const audioConstraints = {
    audio: {
      sampleRate: 16000,  // Lower sample rate for speech
      channelCount: 1,    // Mono for smaller file sizes
      echoCancellation: false,
      noiseSuppression: false,
      autoGainControl: false
    }
  };
  const [mediaRecorder, setMediaRecorder] = useState(null);
  let recordedChunks = [];
  const [mediaStream, setMediaStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [speachtotext, setspeachtotext] = useState("")
  const [showtaptomove, setshowtaptomove] = useState(false)
  const [dbLevel, setdbLevel] = useState()
  var newMediaRecorder;
  var timertoshow;
  const [onstoptext, setonstoptext] = useState("")
  const startRecording = async (number, listofaudios, static_video, positionnum, userinput) => {
    setonstoptext("")
    setshowtaptomove(false)
    const mimeTypes = [
      'audio/webm',
      'audio/webm;codecs=opus',
      'audio/mp4',
      'audio/mpeg',
      'audio/ogg',
      'audio/wav',
    ];

    let supportedMimeType = null;

    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        supportedMimeType = mimeType;
        break;
      }
    }

    if (supportedMimeType) {
      console.log(`Supported mimeType: ${supportedMimeType}`);
      // Proceed with MediaRecorder using the supported mimeType
    } else {
      console.error('No supported mimeType found.');
    }
    sessionStorage.setItem('recording', true)
    setIsRecording(true)
    if (videoRef.current) {
      let numbers = sessionStorage.getItem("videonumber")
      if (listofaudios.length >= numbers) {
         
        videoRef.current.src = static_video;
        videoRef.current.loop = false;

        videoRef.current.play();
      }
    }
    timertoshow = setTimeout(() => {
      setshowtaptomove(true)
    }, 12000);
    let completeTranscript = '';  // To store the full transcription
    try {
      const stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      newMediaRecorder = new MediaRecorder(stream, { supportedMimeType });
      setMediaRecorder(newMediaRecorder);
      // Define the options
      const options = {
        model: "nova-2",     // Model
        language:"en-US",             // Language
        smart_format: true,             // Formatting
        punctuate:true,
        sample_rate: 8000,              // Sample rate
        channels: 1,                    // Number of audio channels
        interim_results: true, 
        vad_events:true,        // Set to false to receive only final results
        endpointing: 500,               // Endpointing silence duration
        utterance_end_ms: "1000",       // Wait for 1000 ms after the last sound
        vad:true
      };
      // Construct the query string
      const queryString = new URLSearchParams(options).toString();
      // Create the WebSocket URL with query parameters
      const url = `wss://api.deepgram.com/v1/listen?${queryString}`;
      socket = new WebSocket(url, [
        'token',
        "79e108f3a4856c0b21288bdb56064575f0e8cdde",
      ]);
      socket.onopen = () => {
        newMediaRecorder.addEventListener('dataavailable', async (event) => {
          if (event.data.size > 0 && socket.readyState == 1) {
            socket.send(event.data);
          }
        });
        console.log('socket open')
      };
     
      newMediaRecorder.start(1100);
      console.log('socket started')
      socket.onmessage = async (message) => {
        
        const received = JSON.parse(message.data);
        console.log(received)
        if (received.channel && received.channel.alternatives) {
          const transcript = received.channel.alternatives[0].transcript;
          const isFinal = received.is_final; // Check if the result is final
          const speech_final = received.speech_final; // Check if the result is final
          if (isFinal) {
            completeTranscript += transcript + ' ';

            setonstoptext(completeTranscript)
          } 

          if(speech_final){
            sessionStorage.setItem('apiresponse',  true)
            stopRecording();
            setIsRecording(false)
            clearTimeout(timertoshow)
            getcorrectWordFromApi(completeTranscript, positionnum)
            sessionStorage.removeItem('recording') 
            if (videoRef.current) {
                if (listofaudios.length > positionnum) {
                    if (positionnum == 1 || positionnum == 2) {
                      sessionStorage.setItem("userinput", true)
                      setuserinput(true)
                    } else  {
                        sessionStorage.removeItem('apiresponse')
                      videoRef.current.src = listofaudios[positionnum].video_path;
                      videoRef.current.play();
                    }
                } else {
                  setshowkeyboard(true)
                  setsuccessmsg(true)
                  if (videoRef.current) {
                    videoRef.current.src = staticvideofromurl;
                    videoRef.current.loop = true;
          
                    videoRef.current.play();
                  }
                }
              }
            if (socket && socket.readyState === WebSocket.OPEN) {
              socket.close(1000, 'Closing connection'); // Close with normal closure code
              console.log('WebSocket 2 closing...');
            } else {
              console.log('WebSocket 2 is already closed or not open');
            }
          }
        }
         
      };
      
    
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };
  const stopRecording = () => {
    setIsRecording(false);
    sessionStorage.removeItem('recording')
    if (newMediaRecorder && newMediaRecorder.state !== "inactive") {
      newMediaRecorder.stop(); // Stop recording
      newMediaRecorder.stream.getTracks().forEach(track => track.stop()); // Stop all audio tracks
      newMediaRecorder = null; // Reset the MediaRecorder
      console.log('Recording stopped');
    }
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close(1000, 'Closing connection'); // Close with normal closure code
      console.log('WebSocket 3 closing...');
    } else {
      console.log('WebSocket 3 is already closed or not open');
    }
     
  };
  const stopRecordingClick = () => {
    setIsRecording(false);
    sessionStorage.removeItem('recording')
    setIsRecording(false)
    clearTimeout(timertoshow)
    let positionnum = sessionStorage.getItem('videonumber')
    getcorrectWordFromApi(onstoptext, positionnum)
    sessionStorage.removeItem('recording') 
    if (videoRef.current) {
        if (listofaudios.length > positionnum) {
            if (positionnum == 1 || positionnum == 2) {
              sessionStorage.setItem("userinput", true)
              setuserinput(true)
            } else  {
              videoRef.current.src = listofaudios[positionnum].video_path;
              videoRef.current.play();
            }
        } else {
          setshowkeyboard(true)
          setsuccessmsg(true)
          if (videoRef.current) {
            videoRef.current.src = staticvideofromurl;
            videoRef.current.loop = true;
  
            videoRef.current.play();
          }
        }
      }
    
     
  };

  const getcorrectWordFromApi = async (data, number, userinput) => {
    sessionStorage.setItem("apiresponse", true)
    let req = { "raw_text": data }
    let desired_variables = {}
    if (number == 1) {
        desired_variables.first_name = ""
        desired_variables.postal_code = ""
        desired_variables.last_name = ""
        desired_variables["Company Name"] = ""
        desired_variables.email = ""
        desired_variables.mobile_number = ""
        desired_variables.Country = ""
        desired_variables.jobtitle = ""
        desired_variables.companywebsite = ""
    }
    if (number == 2) {
        desired_variables.first_name = ""
        desired_variables.postal_code = ""
        desired_variables.last_name = ""
        desired_variables["Company Name"] = ""
        desired_variables.email = ""
        desired_variables.mobile_number = ""
        desired_variables.Country = ""
        desired_variables.jobtitle = ""
        desired_variables.companywebsite = ""
    }
    if (number == 3) {
        setName12(data)
    }
    req.desired_variables = desired_variables
    axios.post(`https://synergytool.pranathiss.com/qxbox/structure_text`, req).then((res) => {

      if (res.data) {
        if (res.data.structured_data.first_name) {
          setName(res.data.structured_data.first_name)
        }
        if (res.data.structured_data.last_name) {
          setName2(res.data.structured_data.last_name)
        }
        if (res.data.structured_data.mobile_number) {
          setName3(res.data.structured_data.mobile_number.toString())
        }
        if (res.data.structured_data.email) {
          setName4(res.data.structured_data.email)
        }
        if (res.data.structured_data.company_name) {
          setName5(res.data.structured_data.company_name)
        }
        if (res.data.structured_data.jobtitle) {
          setName7(res.data.structured_data.jobtitle)
        }
        if (res.data.structured_data.companywebsite) {
          setName6(res.data.structured_data.companywebsite)
        }
        if (res.data.structured_data["Company Name"]) {
          setName5(res.data.structured_data["Company Name"])
        }
        if (res.data.structured_data.Country) {
          setName11(res.data.structured_data.Country)
        }
        if (res.data.structured_data.notes) {
          setName12(res.data.structured_data.notes)
        }
        if (res.data.structured_data.postal_code) {
          setName10(res.data.structured_data.postal_code.toString())
        }
        
        if (videoRef.current) {
          if (listofaudios.length > number) {
            if (number ==1 || number ==2) {
                sessionStorage.removeItem('apiresponse')
              videoRef.current.src = listofaudios[number].video_path;
              videoRef.current.play();
            } 
            
          } else {
            sessionStorage.removeItem('apiresponse')
            setshowkeyboard(true)
            setsuccessmsg(true)
          }
        }
      }
    }).catch((e) => {
      
    })
  }

  const [staticvideofromurl, setstaticvideofromurl] = useState()
  const [disablebutton, setdisablebutton] = useState(false)
  const getVideosfromUser = () => {
    setdisablebutton(true)
    let req = {
      "user_input": "version2"
    }
    axios.post(`https://avengine.pranathiss.com/api/company/synergy/home/diana/`, req).then((res) => {
      if (res.data) {
        setdisablebutton(false)
        setstaticvideofromurl(res.data.static_video)
        if (videoRef.current) {
          videoRef.current.src = res.data.static_video;
          videoRef.current.loop = true;

          videoRef.current.play();
        }
        setlistofaudios([...res.data.questions])
      }
    }).catch((e) => {
      setdisablebutton(false)
    })
  }
   
const micpermission = () => {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(function (stream) {
        console.log('Got stream, time diff :');
      })
      .catch(function (err) {
        console.log('GUM failed with error, time diff: ');
      });
  }
 

 
 
  useEffect(() => {
    micpermission(); // Call this to start detection
    // setTimeout(() => {
    //   stopMicrophoneDetection()
    // }, 5000);
    getVideosfromUser()

  }, [])
  const captervideoimage = () => {
    setdisablebutton(true)
    const formData = new FormData();
    var strImage = "";
    let photovalue = webcamRef.current.getScreenshot()
    if(photovalue){
      strImage = photovalue.replace("data:image/jpeg;base64,", "");
    }
    
    formData.append('photo', strImage);
    formData.append('file_extension',  "jpeg");
    formData.append('session_id', session_id)
    
    axios.post(`https://speech.pranathiss.com/api/photo-synergy/`, formData).then((res) => {
      if (res.data) {
        
      }
    }).catch((e) => {
      setdisablebutton(false)
    })
  }
  const startRecording2 = () => {
     
    if (!disablebutton) {
      captervideoimage();
      if (videoRef.current) {
        videoRef.current.src = listofaudios[0].video_path;
        videoRef.current.loop = false;

        videoRef.current.play();
      }

      videoRef.current.onended = () => {
        let apires = sessionStorage.getItem("apiresponse")
        let temp = 0
        if (sessionStorage.getItem("videonumber")) {
          temp = sessionStorage.getItem("videonumber")
        } else {
          temp = 0
        }
        if (sessionStorage.getItem('recording') == null) {
            if(apires == null){
          sessionStorage.setItem("videonumber", parseInt(temp) + 1)
          let temp2 = parseInt(temp) + 1
          if (listofaudios.length > temp) {
            if(temp != 3){
              startRecording(parseInt(temp) + 1, listofaudios, staticvideofromurl, temp2, userinput)
            }else{
              videoRef.current.src = staticvideofromurl;
              videoRef.current.play();
              setshowkeyboard(true)
              setsuccessmsg(true)
            }
          }
        }else {
            videoRef.current.src = staticvideofromurl;
            videoRef.current.play();
          }
        } else {
          videoRef.current.src = staticvideofromurl;
          videoRef.current.play();
        }

      };
      setplayvideoandaudio(false)
    }
  }
  const submityourdetailstodb = () => {

    let req = {
      
      "first_name": name,
      "last_name": name2,
      "mobile_number": name3,
      "email": name4,
      "job_title":name7,
      "company_website":name6,
      "company_name": name5,
      "street_add":name8,
      "city":name9,
      "postal_code":name10,
      'notes':name12,
      "country": name11 ? name11 : "USA",
    }

    if (name && name2 && name3 && name4 && name5 && name6 && name7 && name10) {


      axios.post(`https://synergytool.pranathiss.com/qxbox/save-user/`, req).then((res) => {
        if (res.data) {
          handleClickOpen(true)
          setshowkeyboard(false)
        }
      }).catch((e) => {

      })
    } else {
      alert("Please enter all details ")
    }
  }

  const [layoutName, setlayoutName] = useState("default")

  const keyboard = useRef(null);
  const [fieldsvalue, setfieldsvalue] = useState()
  const [showkeyboard, setshowkeyboard] = useState(false)
  const [translateEnabled, setTranslateEnabled] = useState(true);

  const onChangevalue = (data) => {

    if (fieldsvalue == 1) {
      
      setName(data);
    } else if (fieldsvalue == 2) {
      setName2(data);
    }
    else if (fieldsvalue == 3) {
      setName3(data);
    }
    else if (fieldsvalue == 4) {
      setName4(data);
    }
    else if (fieldsvalue == 5) {
      setName5(data);
    }
    else if (fieldsvalue == 6) {
      setName6(data);
    }
    else if (fieldsvalue == 7) {
      setName7(data);
    }
    else if (fieldsvalue == 8) {
      setName8(data);
    }
    else if (fieldsvalue == 9) {
      setName9(data);
    }
    else if (fieldsvalue == 10) {
      setName10(data);
    }
    else if (fieldsvalue == 11) {
      setName11(data);
    }
  }
  const onKeyPress = (data) => {
    console.log("Button pressed", data);
    if (data === "{shift}" || data === "{lock}") handleShift();
  }
  const handleShift = () => {
    setlayoutName(prevLayoutName => (prevLayoutName === "default" ? "shift" : "default"));
  };

  const clearInput = (value) => {

    if (value == 0) {
      if (name || name2 || name3 || name4 || name5 || name6 || name7 || name8 || name9 || name10 ) {
        keyboard.current.clearInput();
      }
    } else {
      if (name || name2 || name3 || name4 || name5 || name6 || name7 || name8 || name9 || name10 ) {
        keyboard.current.setInput(value)
      }
    }

    // keyboard.current.setInput() // Clears the keyboard input
  };
  const webcamRef = React.useRef(null);
 
  return (
    <>
      {/* {translateEnabled && <GoogleTranslate />} */}
      <div className='backgroundColor'>
        <div className='colorwhite'>
          <Link to={'/dashboard'} >Dashboard</Link>
        </div>

        <div className='logopss'>

        </div>

        <div >
          <Grid container  >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={"center"} >
              <img src="/img/VITELGLOBAL-LOGO-svg-04.svg" width='300px'  className='logopss mrginleftinbig' />
              <img src="/img/varun-logo-svg-02.svg" width='180px' className='logopss' />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={1} xl={2}></Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={10} xl={12} order={{ xs: 2, sm: 1 }} className='whitecontainer' sx={{ backgroundColor: "#0a1d53", textAlign: "center" }} >

              <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      {/* <p>{speachtotext}</p> */}
                      <Typography variant='h6' className='userformHeader' textAlign={"center"} color={"#fff"}>Speech-Enabled Form Filling   </Typography>
                      <Typography className='userformHeader2' textAlign={"center"} color={"#fff"}>Speech to Fill Form enables users to complete forms using voice commands,<br /> making the process faster and hands-free. It supports various input types,<br /> ensuring an easy and efficient experience.</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name} onClick={() => { setshowkeyboard(true); clearInput(name ? name : 0); setfieldsvalue(1) }} onBlur={() => setshowkeyboard(true)} onChange={(e) => {setName(e.target.value); keyboard.current.setInput(e.target.value)}} placeholder="First Name" />
                      {/* <TextField id="outlined-basic" fullWidth variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name2} onClick={() => { setshowkeyboard(true); clearInput(name2 ? name2 : 0); setfieldsvalue(2) }} onChange={(e) => {setName2(e.target.value); keyboard.current.setInput(e.target.value)}} placeholder="Last Name" />
                      {/* <TextField id="outlined-basic" fullWidth value={name2} onChange={(e) => setName2(e.target.value)} label="Last Name" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name3} onClick={() => { setshowkeyboard(true); clearInput(name3 ? name3 : 0); setfieldsvalue(3) }} onChange={(e) => {setName3(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="Phone Number" />
                      {/* <TextField id="outlined-basic" fullWidth value={name6} onChange={(e) => setName6(e.target.value)} label="County" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name4} onClick={() => { setshowkeyboard(true); clearInput(name4 ? name4 : 0); setfieldsvalue(4) }} onChange={(e) => {setName4(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="Email Address" />
                      {/* <TextField id="outlined-basic" fullWidth value={name4} onChange={(e) => setName4(e.target.value)} label="Email Address" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name5} onClick={() => { setshowkeyboard(true); clearInput(name5 ? name5 : 0); setfieldsvalue(5) }} onChange={(e) => {setName5(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="Company Name" />
                      {/* <TextField id="outlined-basic" fullWidth value={name3} onChange={(e) => setName3(e.target.value)} label="Comapny Name" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name6} onClick={() => { setshowkeyboard(true); clearInput(name6 ? name6 : 0); setfieldsvalue(6) }} onChange={(e) => {setName6(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="Company Website" />
                      {/* <TextField id="outlined-basic" fullWidth value={name6} onChange={(e) => setName6(e.target.value)} label="County" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name7} onClick={() => { setshowkeyboard(true); clearInput(name7 ? name7 : 0); setfieldsvalue(7) }} onChange={(e) => {setName7(e.target.value); keyboard.current.setInput(e.target.value)}} placeholder="Job Title" />
                      {/* <TextField id="outlined-basic" fullWidth value={name5} onChange={(e) => setName5(e.target.value)} label="Phone Number" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    
                    

                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name8} onClick={() => { setshowkeyboard(true); clearInput(name8 ? name8 : 0); setfieldsvalue(8) }} onChange={(e) => {setName8(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="Street Address (optional)" />
                      {/* <TextField id="outlined-basic" fullWidth value={name6} onChange={(e) => setName6(e.target.value)} label="County" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name9} onClick={() => { setshowkeyboard(true); clearInput(name9 ? name9 : 0); setfieldsvalue(9) }} onChange={(e) => {setName9(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="City (optional)" />
                      {/* <TextField id="outlined-basic" fullWidth value={name6} onChange={(e) => setName6(e.target.value)} label="County" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name10} onClick={() => { setshowkeyboard(true); clearInput(name10 ? name10 : 0); setfieldsvalue(10) }} onChange={(e) => {setName10(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="Zip Code " />

                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <input type='text' className='fromfillinginput' value={name11} onClick={() => { setshowkeyboard(true); clearInput(name11 ? name11 : 0); setfieldsvalue(11) }} onChange={(e) => {setName11(e.target.value); keyboard.current.setInput(e.target.value)}}  placeholder="USA" />
                      {/* <TextField id="outlined-basic" fullWidth value={name6} onChange={(e) => setName6(e.target.value)} label="County" variant="outlined" sx={{ mb: 1 }} /> */}
                    </Grid>


                    <Grid item xs={12} sm={12} md={6}>
                      <Button variant="contained" className='fromfillingbutton' onClick={() => submityourdetailstodb()}>Submit</Button>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                    </Grid>
                  </Grid>
                  {/* <Typography variant='h6' className='userformHeader' textAlign={"center"} color={"#000"}>User Form</Typography> */}





                  {/* <TextField id="outlined-basic" fullWidth value={name5} label="Web Site" variant="outlined" sx={{ mb: 1 }} /> */}
                  {/* <TextField id="outlined-basic" fullWidth value={name6} label="City" variant="outlined" sx={{ mb: 1 }} /> */}



                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} order={{ xs: 1, sm: 2 }} textAlign={"center"} className='positionrelative version3'>
                  <video className='videocon' ref={videoRef} playsInline  >
                    Your browser does not support the video tag.
                  </video>
                  {/* {playvideoandaudio ? <div role='button' className=' classspositioncent' onClick={isRecording ? stopRecording : startRecording2}> {isRecording ? "" : <img alt="Play" className='playbuttonbackground' src={playbuttons3} width="80px" />} </div> :
                    isRecording ? <div role='button' className=' classspositioncent' ><><img alt="Mic" className='playbuttonbackground' src={animatedaudio} width="80px" />  </> </div> : ""}
                  {successmsg ? <div role='button' className=' classspositioncent' >
                    <img src="/img/refresh_page.gif" alt="More" onClick={() => window.location.reload()} width="50px" />
                  </div> : ""} */}
                </Grid>
              </Grid>
              {/* {playvideoandaudio ? <div role='button' className=' classspositioncent2' onClick={isRecording ? stopRecording : startRecording2}> {isRecording ? "" : <i class="fa fa-play" aria-hidden="true"></i>} </div> :
                    isRecording ? <div role='button' className=' classspositioncent2' ><><i class="fa fa-microphone" aria-hidden="true"></i>  </> </div> : <div role='button' className=' classspositioncent2' ><i class="fa fa-volume-up" aria-hidden="true"></i></div> }
               */}
              {playvideoandaudio ? <div role='button' className=' classspositioncent2' disabled={disablebutton} onClick={isRecording ? stopRecording : startRecording2}> {isRecording ? "" : <img alt="Play" className='playbuttonbackground' src={playbuttons3} width="80px" />} <div className='speaknowbutton'>Tap to Start</div> </div> :
                isRecording ? <div role='button' className=' classspositioncent2' onClick={stopRecordingClick} ><><img alt="Mic" className='playbuttonbackground' src={playbuttons3} width="80px" />  {showtaptomove ? <div  className='speaknowbutton'>Tap to go forward</div> : ""} <div className='speaknowbutton'>Speak Now</div>  </> </div> : successmsg == "" ? <div role='button' className=' classspositioncent2' onClick={stopRecording} ><div className='Backgroundcolor2'  > <img alt="Mic" className='playbuttonbackground' src={microphoneoff} width="40px" style={{ marginTop: "20px" }} /></div> </div> : ""}
              {successmsg ? <div role='button' className=' classspositioncent2' onClick={stopRecording} ><div className='Backgroundcolor2'  > <img alt="Mic" className='playbuttonbackground' src={refresh} onClick={() => refreshfields()} width="40px" style={{ marginTop: "20px", marginBottom: "20px" }} />  <div className='speaknowbutton'>Refresh</div></div> </div> : ""}
              
            </Grid>
           
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              
              <div className='developedby'>
                <div className='developedtext'>
                  Developed By
                </div>
              <img src="/img/pss-final-logo-01-1.png" width='150px' className='logopss' />
              </div>
              <Webcam
                audio={false}
                width={320}
                height={300}
                className='dnonevisibilitynone'
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                
                videoConstraints={videoConstraints}
              />
            </Grid>
          {showkeyboard ?
            <div className='keyboardclass'>
              <div className='closebuttonkeyboard' onClick={() => setshowkeyboard(false)}>
                X
              </div>
              <Keyboard
                keyboardRef={r => (keyboard.current = r)}
                layoutName={layoutName}
                onKeyPress={onKeyPress}
                onChange={onChangevalue}
              />
            </div> : ""}

        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableEscapeKeyDown

      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Success
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >

        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Your data is successfully saved
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default MessageInputDeepGram2;