import React, { useEffect } from 'react';

const GoogleTranslate = () => {
    useEffect(() => {
        // const addTranslateScript = () => {
        //     const script = document.createElement('script');
        //     script.type = 'text/javascript';
        //     script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        //     script.onload = () => {
        //         if (window.google && window.google.translate) {
        //             new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
        //         }
        //     };
        //     document.body.appendChild(script);
        // };

        // // Ensure script is only added if not already present
        // if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js"]')) {
        //     addTranslateScript();
        // } else if (window.google && window.google.translate) {
        //     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
        // }
    }, []); // Effect only runs on mount

    return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
